
<template>
  <div style="width: 100%; height: 100%; position: relative">
    <!-- 地图 -->
    <el-amap
      :center.sync="amapcenter"
      :map-style="'amap://styles/' + mapstyle"
      :pitch="40"
      :zoom="15"
      :zooms="[10, 25]"
      class="bmap-demo"
    >
      <div v-if="pointstate">
        <!--      应急避难地点列表-->
        <template v-if="httpdata.length">
          <el-amap-marker
            :key="'a' + index"
            v-for="(item, index) in httpdata"
            :icon="icondata.address_path"
            :position="[item.x, item.y]"
            :title="item.name"
            @click="addressesfun(item)"
          >
            <img
              :src="besurl + icondata.address_path"
              style="width: 40px; height: 45px"
            />
          </el-amap-marker>
        </template>
      </div>
    </el-amap>

    <div
      v-if="iseditmapstyle"
      style="
        position: absolute;
        bottom: 20px;
        right: 20px;
        padding: 10px 20px;
        background-color: #091b39;
        border-radius: 10px;
      "
    >
      <el-radio
        v-for="(item, index) in mapstylelist"
        :key="index"
        v-model="mapstyle"
        :label="item.value"
        @input="mapstyleininputfun"
      >
        {{ item.name }}
      </el-radio>
    </div>
  </div>
</template>
<script>
import {
  getcameragetLiveUrl,
  getstatilist,
  getstatistics,
  geturgentcamera,
  organconfig,
  saasinfo,
} from "@/request/api";
import imapimg from "@/assets/luzhou-map.png";
import markericon from "@/assets/frontconstruct/map-point-icon.png";
import markericonsel from "@/assets/frontconstruct/map-point-icon-sel.png";
import LivePlayer from "@liveqing/liveplayer";
import { getOptionCommunity } from "@/request/api";

export default {
  components: { LivePlayer },
  props: {
    // 是否有标点
    pointstate: {
      type: Boolean,
      default: false,
    },
    // 标点列表
    markerlist: {
      type: Array,
      default: () => [],
    },
    // 区块信息列表
    polygonlist: {
      type: Array,
      default: () => [],
    },
    // 是否支持主题变化
    iseditmapstyle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      amapcenter: [],
      httpdata: [],
      icondata: {},
      mapimg: imapimg,
      besurl: process.env.VUE_APP_BASE_URL,
      mapstyle: "darkblue",
      sexiangtouVisible: false,
      sxtdata: null,
      LivePlayerUrl: null,
      markericon: {
        image: markericon,
        imageSize: [153, 93],
      },
      markericonsel: {
        image: markericonsel,
        imageSize: [153, 93],
      },
      mapstylelist: [
        { name: "标准", value: "normal" },
        { name: "极夜蓝", value: "darkblue" },
      ],
      tpolygonlist: this.polygonlist,
    };
  },
  watch: {
    polygonlist(val) {
      this.tpolygonlist = this.polygonlist;
    },
  },
  created() {
    // 这种方式给
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.amapcenter[0] = userinfo.x;
    this.amapcenter[1] = userinfo.y;
  },
  mounted() {
    getOptionCommunity().then((res) => {
      let resdata = res.data;
      this.httpdata = resdata?.street_info?.villages || [];
    });
    getstatilist().then((res) => {
      this.httpdata = res.data;
    });
    getstatistics().then((res) => {
      this.icondata = res.data.icon_info;
    });
    saasinfo().then((res) => {
      if (this.iseditmapstyle) {
        this.mapstyle = res.data.config.BACKGROUND_COLOR.value;
      }
    });
  },
  methods: {
    // 应急避难点
    addressesfun(e) {
      this.$emit("update", e?.villageId);
    },
    // 应急物资
    yingjiwuziVisiblefun(e) {
      this.$emit("wuzifun", e);
    },
    // 摄像头弹窗
    sexiangtouVisiblefun(e) {},

    sxtbeforeclose(e) {
      this.sexiangtouVisible = false;
      this.sxtdata = {};
      this.LivePlayerUrl = "";
    },

    mapstyleininputfun(e) {
      let formd = new FormData();
      formd.append("value", e);
      organconfig(formd).then((res) => {});
    },
    // 区块点击回调
    polygonclick(e) {
      this.$emit("polygonclick", e);
    },
    polygonmouseover(e) {
      this.tpolygonlist.map((item) => {
        if (item.positionGridId === e.positionGridId) {
          item.fillColor = "#0B63B0";
        }
      });
    },
    polygonmouseout(e) {
      this.tpolygonlist.map((item) => {
        if (item.positionGridId === e.positionGridId) {
          item.fillColor = "#00B2D5";
        }
      });
    },
    getpositioninfofun(e) {
      this.$emit("getpositioninfofun", e);
    },
  },
};
</script>
<style lang="less">
/* 图标大小修改 */
//.amap-icon img {
//  width: 40px !important;
//  height: 45px !important;
//}

.jk-box {
  margin-left: 24px;

  .jk-item-style {
    height: 107px;
    width: 546px;
    background-image: url("@/assets/meetdisp/sxt-bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    padding-left: 28px;
    margin: 5px 0;

    .left-icon {
      width: 50px;
      height: 50px;
      margin-right: 80px;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .left-icon-img1 {
      background-image: url("@/assets/meetdisp/jiankong-icon.png");
    }

    .left-icon-img2 {
      background-image: url("@/assets/meetdisp/weizhi-icon.png");
    }

    .left-icon-img3 {
      background-image: url("@/assets/meetdisp/zhilan-icon.png");
    }
  }
}
</style>