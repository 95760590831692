<style lang="less">
/* 图标大小修改 */
//.amap-icon img {
//  width: 40px !important;
//  height: 45px !important;
//}

.jk-box {
  margin-left: 24px;

  .jk-item-style {
    height: 107px;
    width: 546px;
    background-image: url("@/assets/meetdisp/sxt-bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    padding-left: 28px;
    margin: 5px 0;

    .left-icon {
      width: 50px;
      height: 50px;
      margin-right: 80px;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .left-icon-img1 {
      background-image: url("@/assets/meetdisp/jiankong-icon.png");
    }

    .left-icon-img2 {
      background-image: url("@/assets/meetdisp/weizhi-icon.png");
    }

    .left-icon-img3 {
      background-image: url("@/assets/meetdisp/zhilan-icon.png");
    }
  }
}
</style>
<template>
  <div style="width: 100%; height: 100%; position: relative">
    <!-- 地图 -->
    <el-amap
      :center.sync="amapcenter"
      :map-style="'amap://styles/' + mapstyle"
      :pitch="40"
      :zoom="17"
      :zooms="[10, 25]"
      class="bmap-demo"
    >
      <el-amap-layer-satellite :visible="getMapstyle"></el-amap-layer-satellite>
      <!--      区块信息展示-->
      <template v-if="tpolygonlist.length !== 0">
        <el-amap-polygon
          v-for="(item, index) in tpolygonlist"
          :key="item.positionGridId"
          :fill-color="item.fillColor"
          :path="item.block"
          @click="polygonclick(item, $event)"
          @mouseout="polygonmouseout(item, $event)"
          @mouseover="polygonmouseover(item, $event)"
        ></el-amap-polygon>
      </template>

<!-- positionList -->

      <div v-if="pointstate">
        <!--      应急避难地点列表-->
        <template v-if="httpdata.addresses">
          <el-amap-marker
            v-for="(item, index) in httpdata.addresses"
            :key="'a' + index"
            :icon="icondata.address_path"
            :position="[item.y, item.x]"
            :title="item.name"
            @click="addressesfun"
          >
            <img
              :src="besurl + icondata.address_path"
              style="width: 40px; height: 45px"
            />
          </el-amap-marker>
        </template>

        <!--      应急物资列表-->
        <template v-if="httpdata.goods">
          <el-amap-marker
            v-for="(item, index) in httpdata.goods"
            :key="'b' + index"
            :icon="icondata.goods_path"
            :position="[item.x, item.y]"
            :title="item.name"
            @click="yingjiwuziVisiblefun(item.urgentGoodsId)"
          >
            <img
              :src="besurl + icondata.goods_path"
              style="width: 40px; height: 45px"
            />
          </el-amap-marker>
        </template>

        <!--     摄像头点位-->
        <template v-if="httpdata.cameras">
          <el-amap-marker
            v-for="(item, index) in httpdata.cameras"
            :key="'c' + index"
            :icon="besurl + icondata.camera_path"
            :position="[item.longitude, item.latitude]"
            :title="item.name"
            @click="sexiangtouVisiblefun(item.cameraListId)"
          >
            <img
              :src="besurl + icondata.camera_path"
              style="width: 40px; height: 45px"
            />
          </el-amap-marker>
        </template>
      </div>

      <!--     阵地点位信息-->
      <!-- <template v-if="markerlist.length !== 0">
        <el-amap-marker
          v-for="(item, index) in markerlist"
          :key="index"
          :icon="icondata.address_path"
          :position="[item.x, item.y]"
          :title="item.name"
          @click="getpositioninfofun(item.positionUnitId)"
        ></el-amap-marker>
      </template> -->
    </el-amap>

    <div
      v-if="iseditmapstyle"
      style="
        position: absolute;
        bottom: 20px;
        right: 20px;
        padding: 10px 20px;
        background-color: #091b39;
        border-radius: 10px;
      "
    >
      <el-radio
        v-for="(item, index) in mapstylelist"
        :key="index"
        v-model="mapstyle"
        :label="item.value"
        @input="mapstyleininputfun"
      >
        {{ item.name }}
      </el-radio>
    </div>

    <!--    摄像头点位-->
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :visible.sync="sexiangtouVisible"
      title="摄像头详情"
      width="1200px"
      @before-close="sxtbeforeclose"
    >
      <div v-if="sxtdata">
        <div style="display: flex; align-items: center">
          <div style="width: 570px; height: 321px">
            <LivePlayer
              v-if="LivePlayerUrl"
              ref="player2"
              :videoUrl="LivePlayerUrl"
            ></LivePlayer>
          </div>
          <div class="jk-box">
            <div class="jk-item-style">
              <div class="left-icon left-icon-img1"></div>
              <div>
                <div>
                  <span>{{ sxtdata.name }}</span>
                </div>
                <div><span>摄像头名称</span></div>
              </div>
            </div>
            <div class="jk-item-style">
              <div class="left-icon left-icon-img2"></div>
              <div>
                <div><span>位置信息</span></div>
                <div><span>位置</span></div>
              </div>
            </div>
            <div class="jk-item-style">
              <div class="left-icon left-icon-img3"></div>
              <div>
                <div>
                  <span
                    >经度{{ sxtdata.longitude }} 纬度{{
                      sxtdata.latitude
                    }}</span
                  >
                </div>
                <div><span>经纬度</span></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style="margin: 20px 0"><span>详细介绍</span></div>
          <div>
            <span>{{ sxtdata.describe }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getcameragetLiveUrl,
  getstatilist,
  getstatistics,
  geturgentcamera,
  organconfig,
  saasinfo,
  getsmartposition,
} from "@/request/api";
import imapimg from "@/assets/luzhou-map.png";
import markericon from "@/assets/frontconstruct/map-point-icon.png";
import markericonsel from "@/assets/frontconstruct/map-point-icon-sel.png";
import LivePlayer from "@liveqing/liveplayer";

export default {
  components: { LivePlayer },
  props: {
    // 是否有标点
    pointstate: {
      type: Boolean,
      default: false,
    },
    // 标点列表
    markerlist: {
      type: Array,
      default: () => [],
    },
    // 区块信息列表
    polygonlist: {
      type: Array,
      default: () => [],
    },
    // 是否支持主题变化
    iseditmapstyle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getMapstyle: function () {
      return this.mapstyle == "卫星图";
    },
  },
  data() {
    return {
      positionList: [],
      amapcenter: [],
      httpdata: [],
      icondata: {},
      mapimg: imapimg,
      besurl: process.env.VUE_APP_BASE_URL,
      mapstyle: "darkblue",
      sexiangtouVisible: false,
      sxtdata: null,
      LivePlayerUrl: null,
      markericon: {
        image: markericon,
        imageSize: [153, 93],
      },
      markericonsel: {
        image: markericonsel,
        imageSize: [153, 93],
      },
      mapstylelist: [
        { name: "标准", value: "normal" },
        { name: "极夜蓝", value: "darkblue" },
        { name: "卫星图", value: "卫星图" },
      ],
      tpolygonlist: this.polygonlist,
    };
  },
  watch: {
    polygonlist(val) {
      this.tpolygonlist = this.polygonlist;
    },
  },
  created() {
    // 这种方式给
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.amapcenter[0] = userinfo.x;
    this.amapcenter[1] = userinfo.y;
  },
  mounted() {
    getstatilist().then((res) => {
      this.httpdata = res.data;
    });
    getstatistics().then((res) => {
      this.icondata = res.data.icon_info;
    });
    //阵地
    getsmartposition().then((res) => {
      let resData = res.data;
      this.positionList = resData.positionList || [];
    });
    saasinfo().then((res) => {
      if (this.iseditmapstyle) {
        this.mapstyle = res.data.config.BACKGROUND_COLOR.value;
      }
    });
  },
  methods: {
    // 应急避难点
    addressesfun(e) {
      console.log("点击了避难点");
    },
    // 应急物资
    yingjiwuziVisiblefun(e) {
      this.$emit("wuzifun", e);
    },
    // 摄像头弹窗
    sexiangtouVisiblefun(e) {
      this.$emit("sxtfun", e);
      this.sexiangtouVisible = true;
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 获取摄像头信息
      geturgentcamera({
        cameraListId: e,
      })
        .then((res) => {
          this.sxtdata = res.data;
          // 获取摄像头地址
          getcameragetLiveUrl({
            cameraListId: e,
          })
            .then((res) => {
              this.LivePlayerUrl = res.data.liveUrl;
              loading.close();
            })
            .catch((err) => {
              loading.close();
            });
        })
        .catch((err) => {
          loading.close();
        });
    },

    sxtbeforeclose(e) {
      this.sexiangtouVisible = false;
      this.sxtdata = {};
      this.LivePlayerUrl = "";
    },

    mapstyleininputfun(e) {
      let formd = new FormData();
      formd.append("value", e);
      organconfig(formd).then((res) => {});
    },
    // 区块点击回调
    polygonclick(e) {
      this.$emit("polygonclick", e);
    },
    polygonmouseover(e) {
      this.tpolygonlist.map((item) => {
        if (item.positionGridId === e.positionGridId) {
          item.fillColor = "#0B63B0";
        }
      });
    },
    polygonmouseout(e) {
      this.tpolygonlist.map((item) => {
        if (item.positionGridId === e.positionGridId) {
          item.fillColor = "#00B2D5";
        }
      });
    },
    getpositioninfofun(e) {
      this.$emit("getpositioninfofun", e);
    },
  },
};
</script>
